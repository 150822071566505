import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const creditHtmlContent = (
  <div className="col-md-12 mx-auto">
    <p className="lead">
      By clicking the check box following the link to this notice and continuing
      with the application process, you understand and agree that you are
      authorizing Nextate Credit LLC, DBO Trustic ("Trustic") to obtain consumer
      reports and related information about you, now and periodically for as
      long as you remain a customer of Trustic, from one or more consumer
      reporting agencies.
    </p>
    <p className="lead">
      Pursuant to this authorization, you consent to Trustic using your consumer
      reports for any lawful purpose, including but not limited to: (1)
      authenticating your identity; (2) making credit decisions; (3) marketing
      products and services from Trustic or a third party; (4) assisting with
      internal modeling and analysis; (5) servicing your loan; and (6)
      facilitating the sale of your loan or interests associated with your loan
      (or portions thereof) to third party investors, and for related purposes.
      In connection with these purposes, you authorize Trustic to share all or
      parts of your consumer reports with agents, partners, counterparties, or
      affiliates.
    </p>
    <p className="lead">
      You direct Trustic to provide on your behalf certain elements of your
      application, as well as consumer report information, but not including
      your name, to third party investors working with the Trustic (Investors)
      to use this information in making decisions whether to commit funds to
      purchase your loan and/or securities or other interests associated with
      your loan (or portions thereof). Such Investor decisions, in turn, may
      assist Trustic in making decisions related to your loan. You also
      authorize Trustic to provide such information to Investors in connection
      with the sale or resale of your loan and securities or other interests
      associated with your loan (or portions thereof).
    </p>
    <p className="lead">
      You also authorize Trustic to verify information in your application, and
      you agree that Trustic may contact third parties to verify any such
      information.
    </p>
    <p className="lead">
      You authorize and agree that Trustic may transfer or otherwise provide
      your credit report or any information contained therein to any
      successor-in-interest of Trustic or any purchaser or servicer of your
      issued loan, including certain Investors, whether in whole or in part.
    </p>
    <p className="lead">
      Checking your rate generates a soft credit inquiry, which is visible only
      to you. A hard credit inquiry, that may affect your credit score, only
      appears when your loan is issued.
    </p>
  </div>
)

const Credit = () => (
  <Layout
    headerComponent={
      <header className="header pt-8 pb-4 bg-dark text-white text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <h1>Credit Profile Authorization.</h1>
              <p className="lead-2 opacity-90 mt-6">
                Last update: May 20, 2021
              </p>
            </div>
          </div>
        </div>
      </header>
    }
  >
    <section className="section py-7">
      <div className="container">
        <div className="row">{creditHtmlContent}</div>
      </div>
    </section>
  </Layout>
)

export default Credit

export { creditHtmlContent }
